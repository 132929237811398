import { createBatch, createId, getFirestoreDoc, getFirestoreDocs, firestoreIncrement, firebaseArrayRemove, firebaseArrayUnion } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { getUid } from '@/firebase/auth'
import localizeFilter from '@/filters/localize.filter'
import Currencies from '@/libraries/currencies'
import appCardsColors from '@/libraries/app-cards-colors'
import useNotifications from '@/composables/useNotifications'
import { checkValues } from '@/helpers/check-values'

export default {
  state: {
    moneyBoxes: {},
    moneyBoxesReset: 0,
    moneyBoxesFilters: {
      section: null,
      accountId: null,
      currencyCode: null,
      searchStr: ''
    },
    moneyBoxesSort: {
      field: 'MoneyBox',
      direction: 'asc'
    },
    moneyBoxesShow: {
      add: false,
      delete: null,
      accounts: null,
      filters: false
    }
  },
  mutations: {
    setMoneyBox(state, { moneyBoxId, data }) {
      if (moneyBoxId && data) {
        state.moneyBoxes[moneyBoxId] = data
      }
    },
    updateMoneyBox(state, { moneyBoxId, data }) {
      if (moneyBoxId && data) {
        if (!state.moneyBoxes[moneyBoxId]) {
          state.moneyBoxes[moneyBoxId] = {}
        }

        for (const field of Object.keys(data)) {
          state.moneyBoxes[moneyBoxId][field] = data[field]
        }
      }
    },
    deleteMoneyBoxFromState(state, moneyBoxId) {
      if (moneyBoxId && state.moneyBoxes[moneyBoxId]) {
        delete state.moneyBoxes[moneyBoxId]
      }
    },
    deleteAccountFromAllMoneyBoxes(state, accountId) {
      let moneyBoxesIds = Object.keys(state.moneyBoxes)

      if (moneyBoxesIds.length) {
        moneyBoxesIds = moneyBoxesIds.filter(moneyBoxId => {
          const moneyBox = state.moneyBoxes[moneyBoxId]
          return (moneyBox.accounts && moneyBox.accounts.length && moneyBox.accounts.includes(accountId))
        })
      }

      if (moneyBoxesIds.length) {
        for (const moneyBoxId of moneyBoxesIds) {
          const newAccounts = state.moneyBoxes[moneyBoxId].accounts.slice(0)
          newAccounts.splice(newAccounts.indexOf(accountId), 1)
          state.moneyBoxes[moneyBoxId].accounts = newAccounts
        }
      }
    },
    resetMoneyBoxes(state) {
      state.moneyBoxesReset = Date.now()
    },
    setMoneyBoxesFilters(state, filters) {
      state.moneyBoxesFilters = filters
    },
    setMoneyBoxesFiltersSearchStr(state, value) {
      state.moneyBoxesFilters.searchStr = value
    },
    setMoneyBoxesSortDirection(state, direction) {
      state.moneyBoxesSort.direction = direction
    },
    setMoneyBoxesSortField(state, field) {
      state.moneyBoxesSort.field = field
    },
    setMoneyBoxesShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.moneyBoxesShow[field] = show[field]
        }
      }
    },
    setMoneyBoxesFiltersSection(state, section) {
      state.moneyBoxesFilters.section = section
    },
    clearMoneyBoxesFilters(state) {
      state.moneyBoxesFilters = {
        section: null,
        accountId: null,
        currencyCode: null,
        searchStr: ''
      }
    },
    clearMoneyBoxesOneFilter(state, filter) {
      if (filter) {
        const filters = state.moneyBoxesFilters

        if (filter === 'Account') {
          filters.accountId = null
        } else if (filter === 'Currency') {
          filters.currencyCode = null
        }

        state.moneyBoxesFilters = filters
      }
    },
    clearMoneyBoxesSort(state) {
      state.moneyBoxesSort = {
        field: 'MoneyBox',
        direction: 'asc'
      }
    },
    clearMoneyBoxesShow(state) {
      state.moneyBoxesShow = {
        add: false,
        delete: null,
        accounts: null,
        filters: false
      }
    },
    clearMoneyBoxes(state) {
      state.moneyBoxes = {}
      state.moneyBoxesReset = 0
    },
    clearInfo(state) {
      state.moneyBoxes = {}
      state.moneyBoxesFilters = {
        section: null,
        accountId: null,
        currencyCode: null,
        searchStr: ''
      }
      state.moneyBoxesSort = {
        field: 'MoneyBox',
        direction: 'asc'
      }
      state.moneyBoxesShow = {
        add: false,
        delete: null,
        accounts: null,
        filters: false
      }
    }
  },
  actions: {
    async fetchMoneyBoxes({ commit, dispatch, getters }) {
      if (getters.lessonStep || !getters.getLimitNumber('maxMoneyBoxes')) { return }

      const t = tracePerformance('fetchMoneyBoxes')
      t.start()

      try {
        const request = {
          order: [['timestamp', 'asc']],
          wheres: [['owner', '==', getUid()]]
        }

        const tarifLimit = getters.countFetchLimit('maxMoneyBoxes')
        if (tarifLimit === undefined || tarifLimit === 0) {
          return false
        } else if (tarifLimit && Number.isInteger(tarifLimit)) {
          request.maxLimit = tarifLimit
        }

        const moneyBoxes = await getFirestoreDocs({
          collectionName: 'moneyBoxes',
          ...request
        })

        if (moneyBoxes) {
          for (const moneyBox of moneyBoxes) {
            await dispatch('moneyBoxFetched', { moneyBoxId: moneyBox.id, data: moneyBox.data })
          }
        }

        await commit('setLoadedAll', { field: 'moneyBoxes', value: true })

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchMoneyBoxes', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchMoneyBox({ dispatch, getters }, moneyBoxId) {
      if (getters.lessonStep || !moneyBoxId) { return }

      const t = tracePerformance('fetchMoneyBox')
      t.start()

      try {
        const data = await getFirestoreDoc('moneyBoxes', moneyBoxId)

        if (data) {
          await dispatch('moneyBoxFetched', { moneyBoxId, data })
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchMoneyBox', params: { moneyBoxId } })
        return false
      } finally {
        t.stop()
      }
    },
    async moneyBoxFetched({ commit, getters, dispatch }, { moneyBoxId, data }) {
      if (!moneyBoxId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (data.accounts && Array.isArray(data.accounts) && data.accounts.length) {
        for (const accountId of data.accounts) {
          const account = getters.accounts[accountId]
          if (!account) { await dispatch('fetchAccount', accountId) }
        }
      }

      await commit('setMoneyBox', { moneyBoxId, data })
      await commit('resetMoneyBoxes')
    },
    addMoneyBoxButtonClicked({ commit, getters }, { from }) {
      if (!getters.getLimitNumber('maxMoneyBoxes') || !getters.getAvailableLimitNumber('moneyBoxesReset', 'moneyBoxes', 'maxMoneyBoxes')) {
        commit('setAppShowField', { payWall: true })
        return
      }

      commit('clearMoneyBoxesShow')
      commit('setMoneyBoxesShowField', { add: true })

      if (from) { logAnalyticsEvent('addMoneyBoxButtonClicked', { from }) }
    },
    showFilterMoneyBoxesButtonClicked({ commit }, { section, value }) {
      commit('setMoneyBoxesFiltersSection', section)
      commit('setMoneyBoxesShowField', { filters: value })
    },
    clearMoneyBoxesFiltersClicked({ commit }) {
      commit('clearMoneyBoxesFilters')
    },
    async createMoneyBox({ commit, dispatch, getters }, { name = '' }) {
      if (getters.lessonStep || !getters.online) { return }

      if (name) { name = name.trim() }

      if (!name || name.length > checkValues('moneyBoxName', 'max')) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getAvailableLimitNumber('moneyBoxesReset', 'moneyBoxes', 'maxMoneyBoxes') || !getters.getLimitNumber('maxMoneyBoxes') || (+getters.userStats('moneyBoxes') >= +getters.getLimitNumber('maxMoneyBoxes'))) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('createMoneyBox')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const data = {
          accounts: [],
          color: 'white',
          timestamp: syncTimestamp,
          name: name.trim(),
          owner: getUid()
        }

        commit('setProcessing', true)

        const moneyBoxId = createId('moneyBoxes')

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'moneyBoxes',
            id: moneyBoxId,
            data,
            updateStats: 1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('moneyBoxAdded', { moneyBoxId, data })

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        logAnalyticsEvent('moneyBoxCreated')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createMoneyBox', params: { name }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async moneyBoxAdded({ commit }, { moneyBoxId, data }) {
      if (!moneyBoxId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('setMoneyBox', { moneyBoxId, data })
      await commit('increaseTotalNumberOf', { field: 'moneyBoxes', number: 1 })
      await commit('resetMoneyBoxes')
    },
    async editMoneyBoxOneField({ commit, dispatch, getters }, { moneyBoxId, data }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!moneyBoxId || !data || !Object.keys(data).length) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimitNumber('maxMoneyBoxes') || (+getters.userStats('moneyBoxes') > +getters.getLimitNumber('maxMoneyBoxes'))) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      const moneyBox = getters.moneyBoxes[moneyBoxId]
      if (!moneyBox) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      let logField = null
      const newData = {}

      if (data.color !== undefined) {
        logField = 'color'

        if (!getters.canUseLimit('moneyBoxColor', true)) {
          commit('setAppShowField', { payWall: true })
          return
        }

        if (!appCardsColors[data.color]) {
          commit('setError', localizeFilter('Error'))
          return false
        }

        if (Object.keys(data).length === 1) {
          if (moneyBox.color === data.color) { return true }
        }

        newData.color = data.color
      } else if (data.name !== undefined) {
        logField = 'name'

        if (!data.name || !data.name.trim()) {
          commit('setError', localizeFilter('Error'))
          return false
        }

        if (moneyBox.name === data.name.trim()) {
          return true
        }

        newData.name = data.name.trim()
        if (newData.name.length > checkValues('moneyBoxName', 'max')) { newData.name = newData.name.substring(0, checkValues('moneyBoxName', 'max')) }
      }

      if (!Object.keys(newData).length) { return true }

      const t = tracePerformance('editMoneyBoxOneField')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'moneyBoxes',
            id: moneyBoxId,
            data: newData
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('moneyBoxEdited', { moneyBoxId, data: newData })
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        if (logField) { logAnalyticsEvent('moneyBoxEdited', { field: logField }) }
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'editMoneyBoxOneField', params: { moneyBoxId, data }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async addMoneyBoxAccount({ commit, dispatch, getters }, { moneyBoxId = null, accountId = null }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!getters.getLimitNumber('maxMoneyBoxes') || !+getters.userStats('moneyBoxes') > getters.getLimitNumber('maxMoneyBoxes')) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      if (!moneyBoxId || !accountId || !getters.moneyBoxes[moneyBoxId] || !getters.moneyBoxes[moneyBoxId].accounts || getters.moneyBoxes[moneyBoxId].accounts.includes(accountId)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('addMoneyBoxAccount')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const newAccounts = getters.moneyBoxes[moneyBoxId].accounts.slice(0)
        newAccounts.push(accountId)

        const data = {
          accounts: newAccounts
        }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'moneyBoxes',
            id: moneyBoxId,
            data: {
              accounts: firebaseArrayUnion(accountId)
            }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('moneyBoxEdited', { moneyBoxId, data })

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        logAnalyticsEvent('accountIdAddedToMoneyBox')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'addMoneyBoxAccount', params: { moneyBoxId, accountId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async deleteMoneyBoxAccount({ commit, dispatch, getters }, { moneyBoxId, accountId }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!moneyBoxId || !getters.moneyBoxes[moneyBoxId] || !accountId || !getters.moneyBoxes[moneyBoxId].accounts || !getters.moneyBoxes[moneyBoxId].accounts.includes(accountId)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimitNumber('maxMoneyBoxes') || !+getters.userStats('moneyBoxes') > getters.getLimitNumber('maxMoneyBoxes')) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      const t = tracePerformance('deleteMoneyBoxAccount')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        toastId = toastify.warning(localizeFilter('RemovingAccountFromMoneyBox') + '...', { timeout: null })

        const newAccounts = getters.moneyBoxes[moneyBoxId].accounts.slice(0)
        newAccounts.splice(newAccounts.indexOf(accountId), 1)

        const data = {
          accounts: newAccounts
        }

        commit('setProcessing', true)

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'moneyBoxes',
            id: moneyBoxId,
            data: {
              accounts: firebaseArrayRemove(accountId)
            }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('moneyBoxEdited', { moneyBoxId, data })

        toastify.replace(toastId, localizeFilter('MoneyBoxeAccountRemoved'), 'success')
        logAnalyticsEvent('moneyBoxeAccountRemoved')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteMoneyBoxAccount', params: { moneyBoxId, accountId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async moneyBoxEdited({ getters, commit, dispatch }, { moneyBoxId, data }) {
      if (!moneyBoxId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (data.accounts && data.accounts.length) {
        for (const accountId of data.accounts) {
          if (!getters.accounts[accountId]) {
            await dispatch('fetchAccount', accountId)
          }
        }
      }

      await commit('updateMoneyBox', { moneyBoxId, data })
      await commit('resetMoneyBoxes')
    },
    async deleteMoneyBox({ commit, dispatch, getters }, { moneyBoxId = null }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!moneyBoxId || !getters.moneyBoxes[moneyBoxId]) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('deleteMoneyBox')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'moneyBoxes',
            id: moneyBoxId,
            updateStats: -1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('moneyBoxDeleted', moneyBoxId)

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('moneyBoxDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteMoneyBox', params: { moneyBoxId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async deleteAllMoneyBoxes({ commit, dispatch, getters }) {
      if (getters.lessonStep || !getters.online) { return }

      if (!getters.userStats('moneyBoxes')) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('deleteAllMoneyBoxes')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const request = { wheres: [['owner', '==', getUid()]] }

        const moneyBoxes = await getFirestoreDocs({
          collectionName: 'moneyBoxes',
          ...request
        })

        const moneyBoxesToDelete = []

        if (moneyBoxes) {
          for (const moneyBox of moneyBoxes) {
            if (!moneyBoxesToDelete.includes(moneyBox.id)) { moneyBoxesToDelete.push(moneyBox.id) }
          }
        }

        if (!moneyBoxesToDelete.length) {
          toastify.replace(toastId, localizeFilter('Deleted'), 'success')
          return true
        }

        const batchData = []

        for (const moneyBoxId of moneyBoxesToDelete) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'moneyBoxes',
            id: moneyBoxId
          })
        }

        if (moneyBoxesToDelete.length) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              moneyBoxes: firestoreIncrement(moneyBoxesToDelete.length * -1)
            },
            noLogs: true
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        for (const moneyBoxId of moneyBoxesToDelete) {
          await dispatch('moneyBoxDeleted', moneyBoxId)
        }

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('allMoneyBoxesDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteAllMoneyBoxes', params: {}, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async moneyBoxDeleted({ commit }, moneyBoxId) {
      if (!moneyBoxId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('deleteMoneyBoxFromState', moneyBoxId)
      await commit('increaseTotalNumberOf', { field: 'moneyBoxes', number: -1 })
      await commit('resetMoneyBoxes')
    },
    setMoneyBoxesFiltersAction({ commit }, filters) {
      commit('setMoneyBoxesFilters', filters)
      logAnalyticsEvent('setMoneyBoxesFilters', { ...filters })
    }
  },
  getters: {
    moneyBoxes: s => s.moneyBoxes,
    filteredMoneyBoxes: (s, getters) => {
      if (getters.moneyBoxesReset) {
        //
      }

      let moneyBoxesIds = Object.keys(s.moneyBoxes)

      if (moneyBoxesIds.length) {
        if (getters.moneyBoxesFiltered) {
          const moneyBoxContainsCurrencyCode = (moneyBoxId) => {
            if (!moneyBoxId || !getters.moneyBoxes[moneyBoxId] || !getters.moneyBoxes[moneyBoxId].accounts || !getters.moneyBoxes[moneyBoxId].accounts.length) { return false }

            for (const accountId of getters.moneyBoxes[moneyBoxId].accounts) {
              const account = getters.accounts[accountId]
              if (account && account.currencyCode && account.currencyCode === s.moneyBoxesFilters.currencyCode) { return true }
            }
            return false
          }

          const currencyNameContainsSearchStr = (currencyObj) => {
            if (!currencyObj) { return false }
            for (const languageId of Object.keys(currencyObj)) {
              if (currencyObj[languageId] && currencyObj[languageId].toLowerCase().includes(s.moneyBoxesFilters.searchStr.toLowerCase())) { return true }
            }
            return false
          }

          const moneyBoxAnyAccountNameContainsSearchStr = (moneyBoxId) => {
            if (!moneyBoxId || !getters.moneyBoxes[moneyBoxId] || !getters.moneyBoxes[moneyBoxId].accounts || !getters.moneyBoxes[moneyBoxId].accounts.length) { return false }

            for (const accountId of getters.moneyBoxes[moneyBoxId].accounts) {
              const account = getters.accounts[accountId]
              if (account &&
                (account.name && account.name.toLowerCase().includes(s.moneyBoxesFilters.searchStr.toLowerCase()))
                || (
                  account.currencyCode && Currencies[account.currencyCode] && Currencies[account.currencyCode].locales && currencyNameContainsSearchStr(Currencies[account.currencyCode].locales)
                )
              ) { return true }
            }
            return false
          }

          moneyBoxesIds = moneyBoxesIds.filter(moneyBoxId => {
            const moneyBox = s.moneyBoxes[moneyBoxId]

            return (
              (!s.moneyBoxesFilters.accountId || (moneyBox.accounts && moneyBox.accounts.includes(s.moneyBoxesFilters.accountId))) &&
              (!s.moneyBoxesFilters.currencyCode || moneyBoxContainsCurrencyCode(moneyBoxId)) &&
              (!s.moneyBoxesFilters.searchStr ||
                (
                  (moneyBox.name && moneyBox.name.toLowerCase().includes(s.moneyBoxesFilters.searchStr.toLowerCase()))
                  || moneyBoxAnyAccountNameContainsSearchStr(moneyBoxId)
                ))
            )
          })
        }
      }

      if (moneyBoxesIds.length > 1) {
        const sortParameter = s.moneyBoxesSort.field
        const sortType = s.moneyBoxesSort.direction

        if (sortParameter === 'MoneyBox' || !sortParameter) {
          moneyBoxesIds.sort((a, b) => {
            let nameA = s.moneyBoxes[a].name ? s.moneyBoxes[a].name.toLowerCase() : a
            let nameB = s.moneyBoxes[b].name ? s.moneyBoxes[b].name.toLowerCase() : b

            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }
          })
        } else {
          moneyBoxesIds.sort((a, b) => {
            let nameA = s.moneyBoxes[a].name ? s.moneyBoxes[a].name.toLowerCase() : a
            let nameB = s.moneyBoxes[b].name ? s.moneyBoxes[b].name.toLowerCase() : b

            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }
          })
        }

        if (sortType === 'desc') { moneyBoxesIds.reverse() }
      }

      return moneyBoxesIds
    },
    moneyBoxesReset: s => s.moneyBoxesReset,
    moneyBoxesFilters: s => s.moneyBoxesFilters,
    moneyBoxesFiltered: s => {
      if (
        s.moneyBoxesFilters.accountId
        || s.moneyBoxesFilters.currencyCode
        || s.moneyBoxesFilters.searchStr
      ) {
        return true
      }
      return false
    },
    moneyBoxesSort: s => s.moneyBoxesSort,
    moneyBoxesShow: s => s.moneyBoxesShow,
    moneyBoxCurrencyCodes: (s, getters) => moneyBoxId => {
      if (getters.moneyBoxesReset) {
        //
      }

      if (getters.transactionsReset) {
        //
      }

      let answer = []


      if (moneyBoxId && s.moneyBoxes[moneyBoxId] && s.moneyBoxes[moneyBoxId].accounts && s.moneyBoxes[moneyBoxId].accounts.length) {
        for (const accountId of s.moneyBoxes[moneyBoxId].accounts) {
          if (getters.accounts[accountId] && getters.accounts[accountId].currencyCode) {
            if (!answer.includes(getters.accounts[accountId].currencyCode)) {
              if (getters.defaultCurrency && getters.accounts[accountId].currencyCode === getters.defaultCurrency) {
                answer.unshift(getters.accounts[accountId].currencyCode)
              } else {
                answer.push(getters.accounts[accountId].currencyCode)
              }
            }
          }
        }
      }

      return answer
    },
    moneyBoxTotalSums: (s, getters) => moneyBoxId => {
      if (getters.moneyBoxesReset) {
        //
      }

      if (getters.transactionsReset) {
        //
      }

      let answer = {}

      if (moneyBoxId) {
        const currencies = getters.moneyBoxCurrencyCodes(moneyBoxId)

        if (currencies && currencies.length) {
          for (const currencyCode of currencies) {
            if (!answer[currencyCode]) { answer[currencyCode] = 0 }

            if (s.moneyBoxes[moneyBoxId] && s.moneyBoxes[moneyBoxId].accounts && s.moneyBoxes[moneyBoxId].accounts.length) {
              for (const accountId of s.moneyBoxes[moneyBoxId].accounts) {
                if (getters.accounts[accountId] && getters.accounts[accountId].currencyCode && getters.accounts[accountId].currencyCode === currencyCode && getters.accounts[accountId].sum) {
                  answer[currencyCode] = answer[currencyCode] + getters.accounts[accountId].sum
                }
              }
            }
          }
        }
      }

      return answer
    },
    shownMoneyBoxesData: (s, getters) => {
      if (getters.moneyBoxesReset) {
        //
      }

      let answer = {
        accounts: [],
        currencies: []
      }

      const allMoneyBoxes = Object.keys(s.moneyBoxes)

      if (allMoneyBoxes.length) {
        for (const moneyBoxId of allMoneyBoxes) {
          const moneyBox = s.moneyBoxes[moneyBoxId]

          if (moneyBox.accounts && moneyBox.accounts.length) {
            for (const accountId of moneyBox.accounts) {
              const account = getters.accounts[accountId]
              if (account && !answer.accounts.includes(accountId)) {
                answer.accounts.push(accountId)
              }

              if (account && account.currencyCode && !answer.currencies.includes(account.currencyCode)) {
                answer.currencies.push(account.currencyCode)
              }
            }
          }
        }
      }

      return answer
    },
    moneyBoxesFilteredBySections: (s, getters) => {
      return {
        'Account': !!(getters.moneyBoxesFilters.accountId),
        'Currency': !!(getters.transactionsFilters.currencyCode)
      }
    }
  }
}